<template>
  <div id="app" class="flex flex-col min-h-screen font-sans">
    <header class="bg-gradient-to-r from-blue-300 via-blue-400 to-blue-500 text-white shadow-md">
      <nav class="container mx-auto px-6 py-3 flex justify-between items-center">
        <div class="flex items-center space-x-4">
          <!-- Only display the Home link for Administrators when authenticated -->
          <router-link v-if="isAuthenticated && userPosition === 'Purchase'" to="/HomePur" class="flex items-center space-x-2 text-xl font-bold hover:text-teal-200 transition duration-300">
        <HomeIcon class="w-5 h-5" />
        <span>Home</span>
      </router-link>

          <router-link v-if="isAuthenticated && userPosition === 'Maintenance' || userPosition === 'Transport'" to="/HomeRepair" class="flex items-center space-x-2 text-xl font-bold hover:text-teal-200 transition duration-300">
            <HomeIcon class="w-5 h-5" />
            <span>Home</span>
          </router-link>
 <!--  แก้ตรงนี้-->
          <div v-if="isAuthenticated && (userPosition === 'Purchase' || (userPosition === 'Maintenance' && userAgency === 'HQ (สำนักงานใหญ่)'))" class="relative" ref="dropdown">

            <button @click="toggleDropdown" class="flex items-center space-x-2 text-xl font-bold hover:text-teal-200 transition duration-300">
    <i class="fas fa-shopping-cart w-5 h-5"></i>
    <span>ข้อมูลสินค้า</span>
  </button>
            <div v-if="showDropdown" class="absolute bg-white shadow-lg rounded mt-2 w-48">
              <router-link to="/products" class="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-pink-500 hover:text-white">
      <i class="fas fa-box w-4 h-4 mr-2"></i> <!-- ไอคอนสำหรับรายการสินค้า -->
      รายการสินค้า
    </router-link>
    <router-link to="/product-types" class="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-blue-500 hover:text-white">
      <i class="fas fa-tags w-4 h-4 mr-2"></i> <!-- ไอคอนสำหรับประเภทสินค้า -->
      ประเภทสินค้า
    </router-link>
    <router-link to="/brandsProducts" class="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-green-500 hover:text-white">
      <i class="fas fa-registered w-4 h-4 mr-2"></i> <!-- ไอคอนสำหรับยี่ห้อสินค้า -->
      ยี่ห้อสินค้า
    </router-link>
    <router-link to="/vehicleTypes" class="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-yellow-500 hover:text-white">
      <i class="fas fa-car w-4 h-4 mr-2"></i> <!-- ไอคอนสำหรับประเภทรถ -->
      ประเภทรถ
    </router-link>
    <router-link to="/partTypes" class="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-red-500 hover:text-white">
      <i class="fas fa-cogs w-4 h-4 mr-2"></i> <!-- ไอคอนสำหรับประเภทอะไหล่ -->
      ประเภทอะไหล่
    </router-link>
            </div>
          </div>
          <router-link v-if="!isAuthenticated" to="/login" class="flex items-center space-x-2 text-xl font-bold hover:text-teal-200 transition duration-300">
            <LoginIcon class="w-5 h-5" />
            <span>Login</span>
          </router-link>
          <router-link v-if="isAuthenticated && userPosition === 'Insurance'" to="/insurancehome" class="flex items-center space-x-2 text-xl font-bold hover:text-teal-200 transition duration-300">
            <OfficeBuildingIcon class="w-5 h-5" />
            <span>Insurance Home</span>
          </router-link>
          <router-link v-if="isAuthenticated && userPosition === 'Insurance'" to="/vehicles/add" class="flex items-center space-x-2 text-xl font-bold hover:text-teal-200 transition duration-300">
            <PlusCircleIcon class="w-5 h-5" />
            <span>เพิ่มข้อมูลรถ</span>
          </router-link>
          <router-link v-if="isAuthenticated && userPosition === 'Insurance'" to="/vehicles" class="flex items-center space-x-2 text-xl font-bold hover:text-teal-200 transition duration-300">
            <ClipboardListIcon class="w-5 h-5" />
            <span>ดูรายละเอียดข้อมูล</span>
          </router-link>
        </div>

      <div v-if="isAuthenticated" class="flex items-center">
        <div class="relative group">
    <span class="flex items-center cursor-pointer" @click="goToLineConnect">
      <i class="fas fa-user mr-2"></i> <!-- ไอคอนผู้ใช้ -->
      <span class="username">{{ userName }}</span>
    </span>
    <div class="absolute left-0 mt-2 w-64 bg-white border rounded shadow-md p-2 text-left opacity-0 group-hover:opacity-100 transition-opacity duration-300 tooltip-content">
      <p><strong>ตำแหน่ง:</strong> {{ userPosition }}</p>
      <p><strong>หน่วยงาน:</strong> {{ userAgency }}</p>
    </div>
  </div>
  <button @click="logout" class="flex items-center bg-red-600 hover:bg-red-700 transition duration-300 text-white py-2 px-4 rounded">
    <LogoutIcon class="w-5 h-5 mr-2" />
    Logout
  </button>
  </div>

        <!-- <button v-if="isAuthenticated" @click="logout" class="flex items-center bg-red-600 hover:bg-red-700 transition duration-300 text-white py-2 px-4 rounded">
          <LogoutIcon class="w-5 h-5 mr-2" />
          Logout
        </button> -->
      </nav>
    </header>

    <main class="flex-grow">
      <router-view @auth-success="checkAuthStatus"/>
    </main>
    <br><br>
    <footer class="bg-blue-900 text-gray-200 text-center p-3">
      <p>&copy; อยู่ในช่วงกำลังทดสอบปรับปรุง Version 1.01 (beta)</p>
      <!-- <p></p> เพิ่มส่วนนี้เพื่อแสดง label เวอร์ชัน -->
    </footer>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { HomeIcon, LoginIcon, OfficeBuildingIcon, PlusCircleIcon, ClipboardListIcon, LogoutIcon } from '@heroicons/vue/solid';

export default {
  name: 'App',
  components: {
    HomeIcon,
    LoginIcon,
    OfficeBuildingIcon,
    PlusCircleIcon,
    ClipboardListIcon,
    LogoutIcon
  },
  data() {
    return {
      isAuthenticated: false,
      userPosition: null, // เพิ่มตัวแปรนี้เพื่อจัดเก็บตำแหน่งของผู้ใช้
      level_permission:'',
      logoutTimer: null,
      showDropdown: false,
      userName: '',
      userAgency: '',
    };
  },
  created() {
    this.isAuthenticated = !!localStorage.getItem('userToken');
    this.checkAuthStatus();
    this.startInactivityCheck();
  },
  methods: {
    goToLineConnect() {
      // ใช้ Vue Router ในการเปลี่ยนเส้นทางไปยัง '/connect-line'
      this.$router.push({ name: 'LineConnect' });
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    handleClickOutside(event) {
      if (this.showDropdown && (!this.$refs.dropdown || !this.$refs.dropdown.contains(event.target))) {
        this.showDropdown = false;
      }
    },
    closeDropdown(event) {
      if (!this.$el.contains(event.target)) {
        this.showDropdown = true;
      }
    },
    checkAuthStatus() {
      const userToken = localStorage.getItem('userToken');
      this.isAuthenticated = !!userToken;
      this.userPosition = userToken ? localStorage.getItem('userPosition') : null;
      this.userName = userToken ? localStorage.getItem('userName') : '';
      this.userAgency = userToken ? localStorage.getItem('userAgency') : null;
      this.position_lo = userToken ? localStorage.getItem('position_lo') : null;
     // ตรวจสอบว่าค่า level_permission มีหรือไม่
  const levelPermissionStr = localStorage.getItem('level_permission');
  this.level_permission = levelPermissionStr ? parseInt(levelPermissionStr, 10) : 0; // หากค่าว่างหรือไม่ใช่ตัวเลข ให้ตั้งค่าเป็น 0


      console.log('isAuthenticated:', this.isAuthenticated);
      console.log('userPosition:', this.userPosition);
      console.log('userName:', this.userName);
      console.log('userAgency:', this.userAgency);
      console.log('position_lo:', this.position_lo);
      console.log('level_permission:', this.level_permission); // เพิ่มการแสดงค่า level_permission ใน console
      if (this.isAuthenticated && this.userPosition === 'Purchase') {
        console.log('Navigating to /HomePur');
        // this.$router.push('/HomePur');
      } else if (!this.isAuthenticated) {
        console.log('Navigating to /login');
        this.$router.push('/login');
    //   } else {
    // console.log('Navigating to /login');
    // this.$router.push('/login');
  }
  
    },

    logout() {
      localStorage.removeItem('userToken');
      localStorage.removeItem('userPosition');
      localStorage.removeItem('userName'); // ลบชื่อผู้ใช้ออกจาก localStorage
      localStorage.removeItem('userAgency');
      this.userName = ''; // เคลียร์ชื่อผู้ใช้
      this.isAuthenticated = false;
      this.userPosition = null;
      this.userAgency = null;
      this.$router.push('/login');
      this.stopInactivityCheck();
      
    },
    startInactivityCheck() {
      document.addEventListener('mousemove', this.resetTimer);
      document.addEventListener('keydown', this.resetTimer);
      this.resetTimer();
    },
    stopInactivityCheck() {
      document.removeEventListener('mousemove', this.resetTimer);
      document.removeEventListener('keydown', this.resetTimer);
      clearTimeout(this.logoutTimer);
    },
    resetTimer() {
      clearTimeout(this.logoutTimer);
      this.logoutTimer = setTimeout(() => {
        if (this.isAuthenticated) {
          Swal.fire({
            title: 'แจ้งเตือน!',
            text: 'คุณถูกบังคับ Logout เนื่องจากไม่ได้มีการตอบสนอง.',
            icon: 'warning',
            confirmButtonText: 'OK',
            confirmButtonColor: '#FF5733' // Replace with your desired color
          }).then((result) => {
            if (result.isConfirmed) {
              this.logout();
            }
          });
        }
      }, 900000); // 900,000 milliseconds = 15 minutes
    }
  },
  mounted() {
    this.checkAuthStatus();
    this.startInactivityCheck();
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    this.stopInactivityCheck();
    document.removeEventListener('click', this.closeDropdown);
  },
};
</script>

<style>
#app {
  text-align: center;
  font-family: 'Mitr', sans-serif;
  font-weight: 300; /* ExtraLight */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  background-color: #f5f5f5;
  padding: 20px 0;
  width: 100%;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 0 10px;
}

nav ul li a {
  text-decoration: none;
  color: #333;
}

footer {
  background-color: #f5f5f5;
  padding: 0px 10;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.username {
  font-family: 'Mitr', sans-serif;
  font-size: 1.0rem;
  font-weight: 400; /* Regular */
  color: #ffffff;
  margin-right: 1rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  transition: color 0.3s ease-in-out;
}

.username:hover {
  color: #000000;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.group-hover\:opacity-100 {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.tooltip-content {
  background: rgba(255, 255, 255, 0.9); /* พื้นหลังโปร่งใสเล็กน้อย */
  color: #000000; /* สีตัวหนังสือ */
  text-shadow: none; /* เอาเงาตัวหนังสือออก */
  border: 1px solid #ccc; /* สีขอบ */
}
/* สไตล์นี้จะปรับให้ปุ่ม OK ของ SweetAlert2 เป็นสีแดง */
.swal2-confirm {
    background-color: #e3342f !important; /* สีแดง */
    border: none;
    color: white !important;
    font-weight: bold;
    font-size: 1rem;
    padding: 10px 24px;
    border-radius: 5px;
}

.swal2-confirm:hover {
    background-color: #cc1f1a !important; /* สีแดงเข้มเมื่อ hover */
}
/* ปุ่ม Cancel จะเป็นสีเทา */
.swal2-cancel {
    background-color: #6c757d !important; /* สีเทา */
    border: none;
    color: white !important;
    font-weight: bold;
    font-size: 1rem;
    padding: 10px 24px;
    border-radius: 5px;
}

.swal2-cancel:hover {
    background-color: #5a6268 !important; /* สีเทาเข้มเมื่อ hover */
}
@media (orientation: landscape) {
  header {
    flex-direction: row;
  }

  nav ul {
    display: flex;
    justify-content: space-between;
  }
}
</style>
