import { createRouter, createWebHistory } from 'vue-router';
import HomeVieww from '../views/HomeVieww.vue';
import LoginView from '../views/LoginView.vue';
import Register from '../views/RegisterView.vue';
import Forgotpass from '../views/ForgotPasswordView.vue';
import Resetpass from '../views/ResetPasswordView.vue';
import UserLogout from '@/views/UserLogout.vue';

import InsuranceHome from '@/views/InsuranceHome.vue';
import VehicleDetail from '../components/Insurance/VehicleDetail.vue';
import AddVehicle from '../components/Insurance/AddVehicle.vue';
import EditVehicle from '../components/Insurance/EditVehicle.vue';
import VehicleList from '../components/Insurance/VehicleList.vue';
import NotificationsList from '../components/NotificationsList.vue';


import AddSupplier from '../views/Purchase/AddSupplier.vue';
import EditSupplier from '../views/Purchase/EditSupplier.vue';
import ViewSupplier from '@/views/Purchase/ViewSupplier.vue';
import SupplierList from '../views/Purchase/SupplierList.vue';

import HomePur from '@/views/Purchase/HomeView.vue';

import POForm from '../components/Purchase/POForm.vue';
import PRForm from '../components/Purchase/PRForm.vue';
import PREForm from '../components/Purchase/PREForm.vue';
import SupplierTypeManagement from '../components/Purchase/SupplierTypeManagement.vue';
import AddEditSupplierTypeModal from '../components/Purchase/AddEditSupplierTypeModal.vue';

import AddEditProductTypeModal from '@/components/Purchase/Product/AddEditProductTypeModal.vue';
import ProductsList from '@/components/Purchase/Product/ProductsList.vue'; // ใช้ชื่อไฟล์ที่ถูกต้อง
import ProductTypes from '@/components/Purchase/Product/ProductTypes.vue';

import BrandsProducts from '@/components/Purchase/Brand/BrandsProducts.vue';
import VehicleTypes from '@/components/Purchase/VehicleType/VehicleTypes.vue';
import PartTypes from '@/components/Purchase/Part/PartTypes.vue';

import HomeRepair from '@/components/Repair/HomeRepair.vue';
import RepairForm from '@/components/Repair/RepairForm.vue';
import RepairDetails from '@/components/Repair/RepairDetails.vue';

import AdminSettings from '@/views/Admin/AdminSettings.vue';

import AddWarehouse from '@/views/Stock/AddWarehouse.vue';
import StockMenu from '@/views/Stock/StockMenu';
import Allmenu from '@/views/Admin/Allmenu.vue';
import ProductsPage from '@/views/Stock/ProductsPage.vue';
// import PurchaseAdd from '@/views/Stock/PurchaseAdd';
import ImportPage from '@/views/Stock/ImportPage.vue';
import ImportProduct from '@/views/Stock/ImportProduct.vue';
import GarageOutView from '@/views/Purchase/GarageOutView.vue';
import WarehouseIntake from '@/views/Stock/WarehouseIntake';
// import PaymentCheck from '@/views/Stock/PaymentCheck';
// import StockDashboard from '@/views/Stock/StockDashboard';
// import WheelParts from '@/views/Stock/WheelParts';
// import TransactionManage from '@/views/Stock/TransactionManage';
import PRDetail from '@/components/Purchase/PRDetail.vue'; // New component for details
import PODetail from '@/components/Purchase/PODetail.vue'; // New component for details
import DownloadPO  from '@/components/Purchase/DownloadPO.vue'; // New component for details
import ApprovePO   from '@/components/Purchase/ApprovePO.vue'; // New component for details
import POList from '@/components/Purchase/POList.vue';

import RepairHistory  from '@/views/Purchase/RepairHistory.vue';

import EmployeeDetails from '../components/Hr/EmployeeDetails.vue';
import HomeHr from '../views/Hr/HomeHr.vue';

import PurchaseReportPage from '../views/Purchase/PurchaseReportPage.vue';
import PurchaseRequestPage from '../views/Purchase/PurchaseRequestPage.vue';

import LineConnect from '@/views/LineConnect.vue';

import MappingList from '@/views/Purchase/MappingList.vue';
import MappingForm from '@/components/Purchase/MappingForm.vue';

import EmployeeForm from '../components/Hr/EmployeeForm.vue';
import EmployeesList from '@/views/Hr/EmployeesList.vue';
const routes = [
  
  {
    path: '/',
    name: 'Home',
    component: HomeVieww,
    meta: { requiresAuth: true } 
  },
  {
    path: '/employee-form',
    name: 'employee-form',
    component: EmployeeForm
  },
  {
    path: '/employee-list',
    name: 'employee-list',
    component: EmployeesList
  },
  {
    path: '/mapping-list',
    name: 'mapping-list',
    component: MappingList
  },
  {
    path: '/mapping-form',
    name: 'mapping-form',
    component: MappingForm
  },
  {
    path: '/line-connect',
    name: 'LineConnect',
    component: LineConnect
  },
  {
    path: '/purchase-report',
    name: 'PurchaseReport',
    component: PurchaseReportPage
  },
  {
    path: '/purchase-request',
    name: 'PurchaseRequestReport',
    component: PurchaseRequestPage
  },
  {
    path: '/employee',
    name: 'HomeHr',
    component: HomeHr,
  },
  { path: '/employee/:id', name: 'EmployeeDetails', component: EmployeeDetails, props: true },
  {
    path: '/repair-history',
    name: 'RepairHistory',
    component: RepairHistory,
    meta: { requiresAuth: true } 
  },
  {
    path: '/approve-po',
    name: 'ApprovePO',
    component: ApprovePO
  },
  {
    path: '/download-po/:poId/:imageUrl/:groupId',
    name: 'DownloadPO',
    component: DownloadPO,
    meta: { requiresAuth: true }
  },
  {
    path: '/po-list',
    name: 'POList',
    component: POList
  },
  {
    path: '/po-detail/:poId',
  name: 'PODetail',
  component: PODetail,
  props: true
  },
  {
    path: '/pr-detail/:id',
    name: 'PRDetail',
    component: PRDetail,
    props: true
  },
  {
    path: '/repair-garage',
    name: 'GarageOutView',
    component: GarageOutView,
    meta: { requiresAuth: true } 
  },
  {
    path: '/allmenu',
    name: 'Allmenu',
    component: Allmenu,
    meta: { requiresAuth: true } 
  },
  { path: '/stock-menu', name: 'StockMenu', component: StockMenu },
  {
    path: '/products-page/:warehouseId/:groupId',
    name: 'products-page',
    component: ProductsPage,
    meta: { requiresAuth: true }
  },
  // { path: '/purchase-add', name: 'PurchaseAdd', component: PurchaseAdd },
  { path: '/importpage', name: 'ImportPage', component: ImportPage },
  { path: '/import-product', name: 'ImportProduct', component: ImportProduct, },
  
  { path: '/warehouse-intake', name: 'WarehouseIntake', component: WarehouseIntake },
  // { path: '/payment-check', name: 'PaymentCheck', component: PaymentCheck },
  // { path: '/stockdashboard', name: 'StockDashboard', component: StockDashboard },
  // { path: '/wheel-parts', name: 'WheelParts', component: WheelParts },
  // { path: '/transaction-manage', name: 'TransactionManage', component: TransactionManage },
  {
    path: '/add-warehouse',
    name: 'AddWarehouse',
    component: AddWarehouse
  },
  {
    path: '/admin-settings',
    name: 'AdminSettings', // ชื่อคอมโพเนนต์ถูกต้องและเป็น multi-word
    component: AdminSettings,
    meta: { requiresAuth: true }
  },
  {
    path: '/InsuranceHome',
    name: 'InsuranceHome',
    component: InsuranceHome, 
    meta: { requiresAuth: true } 
  },
  {
    path: '/HomeRepair',
    name: 'HomeRepair',
    component: HomeRepair, 
    meta: { requiresAuth: true } 
  },
  {
    path: '/Repair-Form',
    name: 'RepairForm',
    component: RepairForm, 
    meta: { requiresAuth: true } 
  },
  {
    path: '/repair-details/:id',
    name: 'RepairDetails',
    component: RepairDetails
  },
  //จัดซื้อ
  {
    path: '/products',
    name: 'ProductsList',
    component: ProductsList
  },
  // เส้นทางสำหรับ ProductTypes
  {
    path: '/product-types',
    name: 'ProductTypes',
    component: ProductTypes
  },
  {
    path: '/purchase-orders',
    name: 'POForm',
    component: POForm, 
    meta: { requiresAuth: true } 
  },
  {
    path: '/purchase-orders/:id',
    name: 'POFormEdit',
    component: POForm, 
    meta: { requiresAuth: true }
  },
  {
    path: '/purchase-recives',
    name: 'PRForm',
    component: PRForm, 
    props: route => ({ positionLo: route.query.positionLo }),
    meta: { requiresAuth: true } 
  },
  {
    path: '/purchase-requisition',
    name: 'PREForm',
    component: PREForm, 
    meta: { requiresAuth: true } 
  },
  {
    path: '/add-supplier',
    name: 'add-supplier',
    component: AddSupplier,
  },
  {
    path: '/suppliers/view/:supplier_id',
    name: 'ViewSupplier',
    component: ViewSupplier
  },
  {
    path: '/suppliers/edit/:supplier_id', // :id เป็น parameter สำหรับระบุ ID ของคู่ค้า
    name: 'EditSupplier',
    component: EditSupplier,
  },
  {
    path: '/suppliers',
    name: 'suppliers',
    component: SupplierList,
  },
  {
    path: '/brandsProducts',
    name: 'brandsProducts',
    component: BrandsProducts,
  },
  {
    path: '/vehicleTypes',
    name: 'vehicleTypes',
    component: VehicleTypes,
  },
  {
    path: '/partTypes',
    name: 'partTypes',
    component: PartTypes,
  },
  {
    path: '/HomePur',
    name: 'HomePur',
    component: HomePur, 
    meta: { requiresAuth: true } 
  },
  {
    path: '/',
    name: 'AddEditSupplierTypeModal',
    component: AddEditSupplierTypeModal, 
    meta: { requiresAuth: true } 
  },
  {
    path: '/',
    name: 'AddEditProductTypeModal',
    component: AddEditProductTypeModal, 
    meta: { requiresAuth: true } 
  },
  {
    path: '/suppliermanage',
    name: 'suppliermanage',
    component: SupplierTypeManagement, 
    meta: { requiresAuth: true } 
  },
    // ด
  {
    path: '/vehicles/add',
    name: 'AddVehicle',
    component: AddVehicle
  },
  {
    path: '/vehicles/:VehicleID',
    name: 'VehicleDetail',
    component: VehicleDetail,
    props: true
  },
  {
    path: '/NotificationsList',
    name: 'NotificationsList',
    component: NotificationsList
  },
  {
    path: '/vehicles/edit/:VehicleID',
    name: 'EditVehicle',
    component: EditVehicle,
    props: true
  },
  {
    path: '/vehicles',
    name: 'VehicleList',
    component: VehicleList
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/Register',
    name: 'Register',
    component: Register,

  },
  {
    path: '/Forgotpass',
    name: 'Forgotpass',
    component: Forgotpass,
    meta: { requiresAuth: true } 
  },
  {
    path: '/Resetpass',
    name: 'Resetpass',
    component: Resetpass,
    meta: { requiresAuth: true } 
  },
  {
    path: '/AppLogout',
    name: 'UserLogout',
    component: UserLogout
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = localStorage.getItem('userToken');
  const userPosition = localStorage.getItem('userPosition');

  if (requiresAuth && !isAuthenticated) {
    // If the route requires authentication and user is not authenticated
    next({ name: 'Login' }); // Redirect to Login page
  } else if (!requiresAuth && isAuthenticated && to.meta.guest) {
    // If the route is for guests only but the user is authenticated
    next({ name: 'Home' }); // Redirect to home page or other appropriate route
  }else if (requiresAuth && isAuthenticated) {
    if (to.name === 'InsuranceHome' && (userPosition !== 'Insurance' && userPosition !== 'All')) {
      // Specific role-based access check for InsuranceHome, allow 'Insurance' and 'all' positions
      next({ name: 'Unauthorized' }); // Redirect to an unauthorized access page
    } else if (to.name === 'AdminSettings' && userPosition !== 'Admin' && userPosition !== 'All') {
      // Additional role checks as necessary
      next({ name: 'Unauthorized' }); // Redirect if not admin
    } else if (to.name === 'HomePur' && (userPosition !== 'Purchase' && userPosition !== 'All')) {
      next({ name: 'Unauthorized' }); // Redirect if not purchase or all
    }  else {
      next(); // Proceed if authenticated and authorized
    }
  } else {
    next(); // Proceed if none of the above conditions apply
  
  }
});
// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//   const isAuthenticated = localStorage.getItem('userToken');
//   const userPosition = localStorage.getItem('userPosition'); // ดึงตำแหน่งของผู้ใช้จาก localStorage

//   if (requiresAuth && !isAuthenticated) {
//     next('/login');
//   } else if (to.name === 'InsuranceHome' && userPosition !== 'Insurance') {
//     // ถ้าผู้ใช้พยายามเข้าถึงหน้า InsuranceHome แต่ไม่ใช่ตำแหน่ง Insurance
//     next('/'); // หรือเส้นทางที่คุณต้องการเปลี่ยนไป
//   } else {
//     next(); // ถ้าไม่มีปัญหาอื่นๆ ให้เข้าถึงเส้นทางได้
//   }
// });



// router.beforeEach((to, from, next) => {
//     const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//     const isAuthenticated = localStorage.getItem('userToken');
//     console.log(to.path, 'requiresAuth:', to.matched.some(record => record.meta.requiresAuth));
//     console.log('isAuthenticated:', Boolean(localStorage.getItem('userToken')));
//     if (requiresAuth && !isAuthenticated) {
//       next('/login');
//     } else {
//       next();
//     }
//   });

  
export default router;
