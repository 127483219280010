<template>
    <div class="container mx-auto mt-10">
        <div class="bg-blue-600 p-4 text-white">
          <h3 class="text-lg font-semibold mb-0">รายงานสถานะใบขอซื้อ</h3>
        </div>
      <!-- แผนภูมิ -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div class="bg-white shadow-md rounded-lg p-6">
          <h4 class="text-lg font-semibold mb-4">สถานะใบขอซื้อ</h4>
          <div ref="statusChart" style="height: 400px;"></div>
          <p v-if="!statusChartData">Loading...</p>
        </div>
        <div class="bg-white shadow-md rounded-lg p-6">
          <h4 class="text-lg font-semibold mb-4">ยอดรวมตามผู้ขอซื้อ</h4>
          <div ref="creatorChart" style="height: 400px;"></div>
          <p v-if="!creatorChartData">Loading...</p>
        </div>
      </div>
  
      <!-- การ์ดข้อมูล -->
      <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <div class="bg-blue-500 text-white p-4 rounded-lg shadow">
          <h4 class="text-lg font-semibold">ใบขอซื้อทั้งหมด</h4>
          <p class="text-2xl">{{ requisitions.length }}</p>
        </div>
        <div class="bg-indigo-500 text-white p-4 rounded-lg shadow">
          <h4 class="text-lg font-semibold">ผู้ขอซื้อทั้งหมด</h4>
          <p class="text-2xl">{{ creatorsCount }}</p>
        </div>
          <!-- เพิ่มการ์ดสำหรับแต่ละสถานะ -->
        
          <div 
      v-for="(count, status) in statusCounts" 
      :key="status" 
      :class="`${statusColors[status] || 'bg-gray-500'} text-white p-4 rounded-lg shadow`"
    >
      <h4 class="text-lg font-semibold">{{ status }}</h4>
      <p class="text-2xl">{{ count }}</p>
    </div>

      </div>
  
      <!-- ตารางข้อมูลใบขอซื้อ -->
      <div class="bg-white shadow-md rounded-lg overflow-hidden">
       
        <div class="p-6">
          <div class="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
            <div>
              <input 
                type="text" 
                v-model="search" 
                class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                placeholder="Search by PR number or Creator" 
              />
            </div>
            <div>
              <input 
                type="date" 
                v-model="dateFrom" 
                class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                placeholder="From date" 
              />
            </div>
            <div>
              <input 
                type="date" 
                v-model="dateTo" 
                class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                placeholder="To date" 
              />
            </div>
            <div>
                <select 
      v-model="selectedStatus" 
      class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
    >
      <option value="">ทุกสถานะ</option>
      <option v-for="status in statusOptions" :key="status.status_pr" :value="status.status_pr">
        {{ status.status_pr }}
      </option>
    </select>
</div>
            <div class="text-right">
              <button 
                class="w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
                @click="fetchData"
              >
                Search
              </button>
            </div>
          </div>
  
          <div class="overflow-x-auto">
            <table class="min-w-full bg-white border border-gray-300 rounded-lg">
              <thead>
                <tr class="bg-gray-800 text-white">
                  <th class="py-2 px-4 border-b border-gray-200">PR Number</th>
                  <th class="py-2 px-4 border-b border-gray-200">Request Date</th>
                  <th class="py-2 px-4 border-b border-gray-200">Creator</th>
                  <th class="py-2 px-4 border-b border-gray-200">Department</th>
                  <th class="py-2 px-4 border-b border-gray-200">Warehouse</th>
                  <th class="py-2 px-4 border-b border-gray-200">Status</th>
                  <th class="py-2 px-4 border-b border-gray-200">Details</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="requisition in filteredRequisitions" :key="requisition.pr_id" class="hover:bg-gray-100">
                  <td class="py-2 px-4 border-b border-gray-200">{{ requisition.purchase_request_number }}</td>
                  <td class="py-2 px-4 border-b border-gray-200">{{ formatDate(requisition.request_date) }}</td>
                  <td class="py-2 px-4 border-b border-gray-200">{{ requisition.creator_name }}</td>
                  <td class="py-2 px-4 border-b border-gray-200">{{ requisition.department_name }}</td>
                  <td class="py-2 px-4 border-b border-gray-200">{{ requisition.warehouse_name || 'N/A' }}</td>
                  <td class="py-2 px-4 border-b border-gray-200">{{ requisition.status_pr }}</td>
                  <td class="py-2 px-4 border-b border-gray-200">{{ requisition.details }}</td>
                </tr>
                <tr v-if="requisitions.length === 0">
                  <td colspan="8" class="py-4 px-4 text-center text-gray-500">No purchase requests found.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import * as echarts from 'echarts';
  
  export default {
    data() {
      return {
        requisitions: [],
        search: '',
        dateFrom: '',
        dateTo: '',
        selectedStatus: '',
        statusOptions: [], // ตัวแปรเก็บสถานะที่ดึงจาก API
        statusCounts: {}, // เก็บจำนวนรายการสำหรับแต่ละสถานะ
    
        creatorsCount: 0,
        creatorChartData: null,
        statusChartData: null,
      };
    },
    computed: {
      sortedRequisitions() {
        return [...this.requisitions].sort((a, b) => new Date(b.request_date) - new Date(a.request_date));
      },
      filteredRequisitions() {
    return this.requisitions.filter(requisition => {
      return (!this.selectedStatus || requisition.status_pr === this.selectedStatus) &&
             (!this.search || requisition.purchase_request_number.includes(this.search) || requisition.creator_name.includes(this.search)) &&
             (!this.dateFrom || new Date(requisition.request_date) >= new Date(this.dateFrom)) &&
             (!this.dateTo || new Date(requisition.request_date) <= new Date(this.dateTo));
    }).sort((a, b) => new Date(b.request_date) - new Date(a.request_date));
  }
    },
    methods: {
        async fetchStatusOptions() {
  try {
    const response = await axios.get('https://brk-group.org/api/status-options');
    this.statusOptions = response.data;

    // คำนวณจำนวนของแต่ละสถานะ
    const statusCounts = {};
    const statusColors = {};
    const availableColors = ['bg-yellow-500', 'bg-green-500', 'bg-red-500', 'bg-blue-500', 'bg-gray-500', 'bg-orange-500'];

    this.statusOptions.forEach((status, index) => {
      statusCounts[status.status_pr] = this.requisitions.filter(requisition => requisition.status_pr === status.status_pr).length;
      statusColors[status.status_pr] = availableColors[index % availableColors.length]; // ใช้สีจาก availableColors
    });

    this.statusCounts = statusCounts;
    this.statusColors = statusColors;
  } catch (error) {
    console.error('Error fetching status options:', error);
  }
},
      async fetchData() {
        const params = {
          search: this.search,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
        };
        try {
          const response = await axios.get('https://brk-group.org/api/purchase-requests/search', { params });
          this.requisitions = Array.isArray(response.data) ? response.data : [];
  
          this.totalAmount = this.requisitions.reduce((total, requisition) => total + requisition.total_amount, 0);
          this.creatorsCount = new Set(this.requisitions.map(requisition => requisition.creator_name)).size;
  
          await this.fetchCreatorChartData(params);
          await this.fetchStatusChartData(params);
        } catch (error) {
          console.error('Error fetching data:', error);
          this.requisitions = [];
        }
      },
      async fetchCreatorChartData(params = {}) {
    try {
      const response = await axios.get('https://brk-group.org/api/purchase-requests/total-by-creator', { params });
      const creatorData = response.data;

      this.creatorChartData = {
        labels: creatorData.map(data => data.creator_name),
        datasets: [
          {
            label: 'Number of Purchase Requests by Creator',
            data: creatorData.map(data => data.total_amount),
            backgroundColor: 'rgba(75, 192, 192, 0.2)',  // สีของแท่งกราฟ
            borderColor: 'rgba(75, 192, 192, 1)',        // สีขอบของแท่งกราฟ
            borderWidth: 1
          }
        ]
      };
      this.renderCreatorChart();
    } catch (error) {
      console.error('Error fetching creator chart data:', error);
    }
  },
      async fetchStatusChartData(params = {}) {
        try {
          const response = await axios.get('https://brk-group.org/api/purchase-requests/status-distribution', { params });
          if (response.data && response.data.length) {
            this.statusChartData = {
              labels: response.data.map(item => item.status_pr),
              datasets: [
                {
                  label: 'จำนวนสถานะ',
                  data: response.data.map(item => item.count),
                },
              ],
            };
            this.renderStatusChart();
          } else {
            console.error('No data found for status chart');
          }
        } catch (error) {
          console.error('Error fetching status chart data:', error);
        }
      },
      renderCreatorChart() {
    const chartDom = this.$refs.creatorChart;
    const myChart = echarts.init(chartDom);
    const option = {
      title: {
        text: 'Number of Purchase Requests by Creator',
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: '{b0}: {c0}'
      },
      xAxis: {
        type: 'category',
        data: this.creatorChartData.labels || []
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: this.creatorChartData.datasets[0].data || [],
          type: 'bar',
          backgroundColor: this.creatorChartData.datasets[0].backgroundColor,
          borderColor: this.creatorChartData.datasets[0].borderColor,
          borderWidth: this.creatorChartData.datasets[0].borderWidth
        }
      ]
    };
    myChart.setOption(option);
  },
      renderStatusChart() {
        const chartDom = this.$refs.statusChart;
        const myChart = echarts.init(chartDom);
        const option = {
          title: {
            text: 'Order Status Distribution',
            left: 'center'
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          series: [
            {
              name: 'สถานะ',
              type: 'pie',
              radius: '50%',
              data: this.statusChartData.labels.map((label, index) => ({
                value: this.statusChartData.datasets[0].data[index],
                name: label,
              })),
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        myChart.setOption(option);
      },
      formatAmount(amount) {
        return amount !== null && amount !== undefined ? parseFloat(amount).toFixed(2) : '0.00';
      },
      formatDate(date) {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
      },
    },
    mounted() {
      this.fetchData();
      this.fetchStatusOptions();
    },
  };
  </script>
  
  <style scoped>
  .card {
    border-radius: 0.5rem;
  }
  
  .card-header {
    border-bottom: 2px solid #007bff;
  }
  
  .table-responsive {
    margin-top: 20px;
  }
  
  .input-group-text {
    background-color: #f8f9fa;
  }
  
  .btn {
    margin-left: 5px;
  }
  
  .badge {
    font-size: 90%;
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075);
  }

  select {
  color: #000000; /* ตั้งค่าสีของฟอนต์ให้เป็นสีดำ */
  font-family: 'Arial', sans-serif; /* เปลี่ยนฟอนต์ให้รองรับภาษาไทย */
  font-size: 16px; /* ปรับขนาดฟอนต์ */
}

option {
  color: #000000; /* สีของฟอนต์ในตัวเลือก */
  background-color: #ffffff; /* สีพื้นหลังของตัวเลือก */
}
  </style>
