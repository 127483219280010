<template>
  <div class="flex flex-col md:flex-row h-screen">
  <!-- <div class="flex h-screen bg-gray-100"> -->
    <!-- Sidebar -->
    <!-- <div class="w-64 bg-white shadow-md"> -->
      <div :class="{'block md:block': isSidebarOpen, 'hidden md:block': !isSidebarOpen}" 
         class="fixed inset-y-0 left-0 bg-white shadow-md transition-transform duration-300 ease-in-out transform" 
         :style="{transform: isSidebarOpen ? 'translateX(0)' : 'translateX(-100%)'}">
      <nav > 
        <!-- class="mt-10" -->
       <!-- ปุ่มเปิด/ปิด Sidebar เฉพาะหน้าจอขนาดเล็ก -->
   

    <div :class="{'block md:block': isSidebarOpen, 'hidden md:block': !isSidebarOpen}" class="mt-2">
      <button class="block md:hidden bg-blue-500 text-white p-2" @click="toggleSidebar">ปิดเมนู</button>
        <!-- Heading for PR -->
        <div class="px-4 py-2 font-semibold text-gray-700">
          รายการขอซื้อ PR
        </div>
        <button 
          v-if="departmentId === 1" 
          class="block py-2.5 px-4 rounded hover:bg-gray-200 w-full text-left"
          @click="setActiveSection('pr-agency')"
          :class="{ 'bg-gray-200': activeSection === 'pr-agency' }"
        >ขอดำเนินการสั่งซื้อ (หน่วยงานอื่น)</button>

        <button 
         
          class="block py-2.5 px-4 rounded hover:bg-gray-200 w-full text-left"
          @click="setActiveSection('pr-own')"
          :class="{ 'bg-gray-200': activeSection === 'pr-own' }"
        >ขอสั่งซื้อภายใน
      
      </button>


        <!-- Heading for PO -->
        <div class="px-4 py-2 font-semibold text-gray-700 mt-4">
          รายการสั่งซื้อ PO
        </div>
        <button 
          class="block py-2.5 px-4 rounded hover:bg-gray-200 w-full text-left"
          @click="setActiveSection('po-list')"
          :class="{ 'bg-gray-200': activeSection === 'po-list' }"
        >รายการใบสั่งซื้อ</button>
        </div>
      </nav>
    </div>
 <!-- Main Content -->
 <div :class="{'md:ml-64': isSidebarOpen, 'md:ml-0': !isSidebarOpen}" class="transition-all duration-300 ease-in-out p-4 w-full" @click.stop>
      <button class="block bg-blue-500 text-white p-2 mb-4" @click="toggleSidebar">
        {{ isSidebarOpen ? 'ปิดเมนู' : 'เปิดเมนู' }}
      </button>
  <div v-show="activeSection === 'pr-agency'">
    <div class="pr-form">
      <div class="header">
        <div class="bg-white shadow mb-4 p-4 flex justify-between">
          <div class="left">
            <span class="font-semibold text-xl text-gray-800">รายการใบขอซื้อ (หน่วยงาน)</span>
          </div>

       

        </div>
        
      <label for="agencyStatus" class="font-semibold text-lg">สถานะ : </label>
      <select
        id="agencyStatus"
        v-model="statusFilter"
        class="mt-2 border border-gray-300 rounded-md px-3 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
      >
        <option 
          v-for="status in AgencystatusOptions"
          :key="status.value" 
          :value="status.value"
        >
          {{ status.text }} ({{ countAgencyStatus(status.value) }})
        </option>
      </select> 
   

        <div class="left flex gap-2">
              <button 
                @click="setPrType('repair')"
                :class="['px-4 py-2 rounded-md text-sm font-medium', prType === 'repair' ? 'bg-blue-500 text-white' : 'bg-white border border-blue-500 text-blue-500']"
              >
                ซื้อเพื่อซ่อม
              </button>
              <button 
                @click="setPrType('stock')"
                :class="['px-4 py-2 rounded-md text-sm font-medium', prType === 'stock' ? 'bg-blue-500 text-white' : 'bg-white border border-blue-500 text-blue-500']"
              >
                ซื้อเพื่อลงสต็อก
              </button>
            </div>
            
      </div>

      <!-- Table -->
      <div class="overflow-x-auto">
      <table class="min-w-full "> 
        <!-- table-fixed -->
        <thead class="bg-gray-100">
          <tr>
        <th class="p-2 sm:p-3 text-xs sm:text-sm text-center">รายการเปิดใบขอซื้อ</th>
        <th class="p-2 sm:p-3 text-xs sm:text-sm text-center">เลขที่เอกสาร</th>
        <th class="p-2 sm:p-3 text-xs sm:text-sm text-center">วันที่เสนอใบขอซื้อ</th>
        <th class="p-2 sm:p-3 text-xs sm:text-sm text-center">สถานที่รับ</th>
        <th class="p-2 sm:p-3 text-xs sm:text-sm text-center">จำนวนรายการซื้อ</th>
        <th class="p-2 sm:p-3 text-xs sm:text-sm text-center">สถานะ</th>
        <th class="p-2 sm:p-3 text-xs sm:text-sm text-center">การดำเนินการ</th>
      </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(item, index) in paginatedPrListDataExclude" :key="index">
        <td class="p-2 sm:p-3 text-xs sm:text-sm text-center">{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
        <td class="p-2 sm:p-3 text-xs sm:text-sm text-center">{{ item.documentNumber }}</td>
        <td class="p-2 sm:p-3 text-xs sm:text-sm text-center">{{ formatDate(item.purchaseDate) }}</td>
        <td class="p-2 sm:p-3 text-xs sm:text-sm text-center">{{ item.reciveLocation }}</td>
        <td class="p-2 sm:p-3 text-xs sm:text-sm text-center">{{ item.itemCount }}</td>
        <td :class="statusClass(item.status)" class="p-2 sm:p-3 text-xs sm:text-sm text-center">{{ item.status }}</td>
        <td class="p-2 sm:p-3 text-xs sm:text-sm text-center">
          <router-link :to="{ name: 'PRDetail', params: { id: item.id } }" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
            <i class="fas fa-eye "></i>
          </router-link>
        </td>
      </tr>
        </tbody>
      </table>
      </div>
      <!-- Pagination -->
<div class="flex justify-center mt-4 mb-4">
  <button 
    @click="goToPageExclude(1)" 
    :disabled="currentPage === 1"
    class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
  >
    หน้าแรก
  </button>

  <button 
    @click="prevPageExclude" 
    :disabled="currentPage === 1"
    class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
  >
    ย้อนกลับ
  </button>

  <span v-for="page in pageNumbersExclude" :key="page" @click="goToPageExclude(page)" :class="{ 'bg-gray-400 text-white': currentPage === page }"
    class="cursor-pointer px-4 py-2 mx-1 rounded-md"
  >
    {{ page }}
  </span>

  <button 
    @click="nextPageExclude" 
    :disabled="currentPage === totalPagesExclude"
    class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
  >
    ถัดไป
  </button>

  <button 
    @click="goToPageExclude(totalPagesExclude)" 
    :disabled="currentPage === totalPagesExclude"
    class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
  >
    หน้าสุดท้าย
  </button>
          </div>
          <br>
    </div>
  </div>

      <!-- Main Content -->
      <div class="flex-1 p-10">
      <div v-show="activeSection === 'pr-own'">
        <!-- PR Own List Section -->
        <div class="pr-form">
          <!-- Header Area -->
          <div class="header">
            <div class="bg-white shadow mb-4 p-4 flex justify-between">
              <div class="left">
                <span class="font-semibold text-xl text-gray-800">รายการใบขอซื้อ (Purchase Requisition: PR)</span>
              </div>

             
            </div>


           
      <label for="computedStatus" class="font-semibold text-lg">สถานะ : </label>
      <select
        id="computedStatus"
        v-model="statusFilter"
        class="mt-2 border border-gray-300 rounded-md px-3 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
      >
        <option 
          v-for="status in computedStatusOptions"
          :key="status.value" 
          :value="status.value"
        >
          {{ status.text }} ({{ countStatus(status.value) }})
        </option>
      </select>
  

            <div class="left flex gap-2">
              <button 
                @click="setPrType('repair')"
                :class="['px-4 py-2 rounded-md text-sm font-medium', prType === 'repair' ? 'bg-blue-500 text-white' : 'bg-white border border-blue-500 text-blue-500']"
              >
                ซื้อเพื่อซ่อม
              </button>
              <button 
                @click="setPrType('stock')"
                :class="['px-4 py-2 rounded-md text-sm font-medium', prType === 'stock' ? 'bg-blue-500 text-white' : 'bg-white border border-blue-500 text-blue-500']"
              >
                ซื้อเพื่อลงสต็อก
              </button>
            </div>
          </div>

          <!-- Table -->
          <table class="min-w-full table-fixed">
            <thead class="bg-gray-100">
              <tr>
                <th class="p-3 text-center">รายการเปิดใบขอซื้อ</th>
                <th class="p-3 text-center">เลขที่เอกสาร</th>
                <th class="p-3 text-center">วันที่เสนอใบขอซื้อ</th>
                <th class="p-3 text-center">สถานที่รับ</th>
                <th class="p-3 text-center">จำนวนรายการซื้อ</th>
                <th class="p-3 text-center">สถานะ</th>
                <th class="p-3 text-center">การดำเนินการ</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr class="border-b" v-for="(item, index) in paginatedData" :key="index">
                <td class="p-3">{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                <td class="p-3">{{ item.documentNumber }}</td>
                <td class="p-3">{{ formatDate(item.purchaseDate) }}</td>
                <td class="p-3">{{ item.reciveLocation }}</td>
                <td class="p-3">{{ item.itemCount }}</td>
                <td :class="statusClass(item.status)" class="p-3">{{ item.status }}</td>
                <td class="p-3">
                  <router-link :to="{ name: 'PRDetail', params: { id: item.id } }" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                     <i class="fas fa-eye "></i>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Pagination -->
<div class="flex justify-center mt-4">
  <button 
    @click="goToPage(1)" 
    :disabled="currentPage === 1"
    class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
  >
    หน้าแรก
  </button>

  <button 
    @click="prevPage" 
    :disabled="currentPage === 1"
    class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
  >
    ย้อนกลับ
  </button>

  <span v-for="page in pageNumbers" :key="page" @click="goToPage(page)" :class="{ 'bg-gray-400 text-white': currentPage === page }"
    class="cursor-pointer px-4 py-2 mx-1 rounded-md"
  >
    {{ page }}
  </span>

  <button 
    @click="nextPage" 
    :disabled="currentPage === totalPages"
    class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
  >
    ถัดไป
  </button>

  <button 
    @click="goToPage(totalPages)" 
    :disabled="currentPage === totalPages"
    class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
  >
    หน้าสุดท้าย
  </button>
            </div>
            <br>
        </div>
        </div>


      <div v-show="activeSection === 'po-list'">
        <!-- Content for "รายการใบสั่งซื้อ" -->
        <div class="pr-form">
          <!-- Header Area -->
          <div class="header">
            <div class="bg-white shadow mb-4 p-4 flex justify-between">
              <div class="left">
                <span class="font-semibold text-xl text-gray-800">รายการใบสั่งซื้อ</span>
              </div>
              
            </div>

            
      <label for="poStatus" class="font-semibold text-lg">สถานะการสั่งซื้อ : </label>
      <select
        id="poStatus"
        v-model="statusFilter"
        class="mt-2 border border-gray-300 rounded-md px-3 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
      >
        <option 
          v-for="status in poStatusOptions"
          :key="status.value" 
          :value="status.value"
        >
          {{ status.text }} ({{ countPoStatus(status.value) }})
        </option>
      </select>
  
          </div>

          <!-- Table -->
          <table class="min-w-full table-fixed">
            <thead class="bg-gray-100">
              <tr>
                <th class="p-3 text-center">รายการสั่งซื้อ</th>
                <th class="p-3 text-center">เลขที่เอกสาร</th>
                <th class="p-3 text-center">วันที่สั่งซื้อ</th>
                <th class="p-3 text-center">หน่วยงาน</th>
                <th class="p-3 text-center">จำนวนรายการ</th>
                <th class="p-3 text-center">สถานะ</th>
                <th class="p-3 text-center">การดำเนินการ</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr 
  class="border-b" 
  v-for="(item, index) in paginatedPOListData" 
  :key="index"
  :class="{ 'bg-yellow-100': !viewedItems.includes(item.id) }"
>
<td class="p-3">{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                <td class="p-3">{{ item.documentNumber }}</td>
                <td class="p-3">{{ formatDate(item.purchaseDate) }}</td>
                <td class="p-3">{{ item.department }}</td>
                <td class="p-3">{{ item.itemCount }}</td>
                <td :class="statusClass(item.status)" class="p-3">{{ item.status }}</td>
                <td class="p-3">
                  <router-link 
  :to="{ name: 'PODetail', params: { poId: item.id }, query: { fromPRForm: true } }" 
  class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
  @click="markAsViewed(item.id)"
>
<i class="fas fa-eye "></i>
</router-link>
                </td>
              </tr>
            </tbody>
          </table>

    <!-- Pagination -->
<div class="flex justify-center mt-4">
  <button 
    @click="prevPOPage" 
    :disabled="currentPage === 1"
    class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
  >
    ย้อนกลับ
  </button>

  <!-- Display page numbers -->
  <button
    v-for="page in filteredPageNumbers"
    :key="page"
    @click="goToPage(page)"
    :class="['px-4 py-2 mx-1', { 'bg-blue-500 text-white': page === currentPage, 'bg-gray-200 text-gray-600': page !== currentPage }]">
    {{ page }}
  </button>

  <button 
    @click="nextPOPage" 
    :disabled="currentPage === totalPOPages"
    class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
  >
    ถัดไป
  </button>
</div>
<br>
        </div>
      </div>
 
    </div>
  </div>
  </div>

</template>

<script>
import axios from 'axios';
// import socket from '../services/socket'; // ตรวจสอบให้แน่ใจว่า path ถูกต้อง
export default {
  props: {
    positionLo: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeSection: 'pr-own', // Default section
      prType: 'repair', // Default type
      testData: [],
      isSidebarOpen: true,  // ตั้งค่าเริ่มต้นให้ Sidebar ปิด
      viewedItems: [], // เก็บข้อมูลรายการที่ถูกคลิกแล้ว
      filteredData: [],
      
      searchTerm: '',
      statusFilter: 'ทั้งหมด',
      departmentId: null,
      departmentPim: null,
      agency: null, // เพิ่มตัวแปรเพื่อเก็บ agency
      userAgency: 1, // ตัวแปร userAgency ที่จะถูกใช้ในการตรวจสอบเงื่อนไข
      statusOptions: [
        { value: 'ทั้งหมด', text: 'ทั้งหมด' },
        { value: 'รอตรวจสอบ', text: 'รอตรวจสอบ' },
        { value: 'กำลังดำเนินการ', text: 'กำลังดำเนินการ' },
        { value: 'เสร็จสิ้น', text: 'เสร็จสิ้น' },
        { value: 'ยกเลิก', text: 'ยกเลิก' },
      ],
      AgencystatusOptions: [
        { value: 'ทั้งหมด', text: 'ทั้งหมด' },
        { value: 'รอตรวจสอบ', text: 'รอตรวจสอบ' },
        { value: 'รอผจก.หน่วยงานตรวจสอบ', text: 'รอผจก.หน่วยงานตรวจสอบ' },
        { value: 'รอส่วนกลางตรวจสอบ', text: 'รอส่วนกลางตรวจสอบ' },
        { value: 'รอเจ้าหน้าที่ธุรการจัดซื้อ', text: 'รอเจ้าหน้าที่ธุรการจัดซื้อ' },
        { value: 'อยู่ระหว่างการสั่งซื้อ', text: 'อยู่ระหว่างการสั่งซื้อ' },
        { value: 'ยกเลิกใบ PR', text: 'ยกเลิกใบ PR' },
        { value: 'ปิดใบสั่งซื้อ', text: 'ปิดใบสั่งซื้อ' },
        { value: 'สั่งซื้อครบแล้ว', text: 'สั่งซื้อครบแล้ว' },
      ],
      poDetails: [],
      poStatusOptions: [
        { value: 'ทั้งหมด', text: 'ทั้งหมด' },
        { value: 'รอการอนุมัติ', text: 'รอการอนุมัติ' },
        { value: 'รอการสั่งซื้อ', text: 'รอการสั่งซื้อ' },
        { value: 'รอรับอะไหล่', text: 'รอรับอะไหล่' },
        { value: 'รับอะไหล่แล้ว', text: 'รับอะไหล่แล้ว' },
        { value: 'ไม่อนุมัติ', text: 'ไม่อนุมัติ' },
        { value: 'ยกเลิกใบสั่งซื้อ', text: 'ยกเลิกใบสั่งซื้อ' },
      ],
      prListData: [], // Data for "รายการใบขอซื้อแจ้งซ่อม"
      poListData: [], // Data for "รายการใบสั่งซื้อ"
      prListDataExclude: [], // Data for PR List Exclude
      currentPage: 1,
      itemsPerPage: 10,

    };
  },
  async created() {
    const token = localStorage.getItem('userToken');
    if (!token) {
      this.$router.push({ name: 'Login' });
      return;
    }

    try {
      const response = await axios.get('https://brk-group.org/api/user', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      this.user = response.data; // ตรวจสอบให้แน่ใจว่า login_id ถูกดึงมาใน user object
      this.agency = this.user.agency; // ตั้งค่า agency จากข้อมูลผู้ใช้
      this.departmentId = this.user.group_id; // ตั้งค่า group_id จากข้อมูลผู้ใช้
      this.departmentPim = this.user.login_id;
      console.log('Fetched user agency:', this.user.agency);
      console.log('Fetched user group_id:', this.user.group_id);
      console.log('Fetched user login_id:', this.user.login_id);
    } catch (error) {
      console.error('Error fetching user data:', error);
      if (error.response && error.response.status === 401) {
        this.$router.push({ name: 'Login' });
      } else {
        alert('เกิดข้อผิดพลาดในการดึงข้อมูลผู้ใช้');
      }
    }
  
    this.fetchPrListData(); // Fetch initial data for the default section
    this.fetchPoListData();
    this.fetchPrListDataExclude();
    this.loadViewedItems();

    //   // เพิ่มการเชื่อมต่อ WebSocket
    //   this.$socket.on('message', (message) => {
    //   if (message.type === 'pr_status_updated') {
    //     console.log('PR status updated:', message.data);
    //     this.fetchPrListData(); // อัปเดตข้อมูลเมื่อมีการเปลี่ยนแปลงสถานะ PR
    //   }
    // });
  },
  computed: {
    pageNumbersExclude() {
    let startPage = 1;
    let endPage = this.totalPagesExclude;

    if (this.totalPagesExclude > 5) {
      if (this.currentPage <= 3) {
        endPage = 5;
      } else if (this.currentPage + 2 >= this.totalPagesExclude) {
        startPage = this.totalPagesExclude - 4;
      } else {
        startPage = this.currentPage - 2;
        endPage = this.currentPage + 2;
      }
    }

    let pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  },
    paginatedPrListDataExclude() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredPrListDataExclude.slice(start, end);
    },
    paginatedPOListData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredPoItems.slice(start, end);
    },
    totalPagesExclude() {
      return Math.ceil(this.filteredPrListDataExclude.length / this.itemsPerPage);
    },
    computedStatusOptions() {
      return this.departmentId === 1 ? this.statusOptions : this.AgencystatusOptions;
    },
    filteredPrListDataExclude() {
      const filtered = this.prListDataExclude.filter(item => {
        const typeMatches = item.purchaseType === this.prType;
        const statusMatches = this.statusFilter === 'ทั้งหมด' || item.status === this.statusFilter;
        return typeMatches && statusMatches;
      });
      console.log('Filtered data:', filtered);
      return filtered;
    },
    filteredPrListData() {
      const filtered = this.prListData.filter(item => {
        const typeMatches = item.purchaseType === this.prType;
        const statusMatches = this.statusFilter === 'ทั้งหมด' || item.status === this.statusFilter;
        return typeMatches && statusMatches;
      });
      console.log('Filtered PR data:', filtered);
      return filtered;
    },
    filteredPoItems() {
      if (this.statusFilter === 'ทั้งหมด') {
        return this.poListData;
      }
      return this.poListData.filter(po => po.status === this.statusFilter);
    },
    paginatedData() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    const paginated = this.filteredPrListData.slice(start, end);
    
    console.log('Paginated data:', paginated); // ตรวจสอบข้อมูลที่แบ่งหน้า
    return paginated;
  },
    totalPages() {
      return Math.ceil(this.filteredPrListData.length / this.itemsPerPage);
    },
    totalPOPages() {
    return Math.ceil(this.filteredPoItems.length / this.itemsPerPage);
  },
  filteredPageNumbers() {
    const totalPages = this.totalPOPages;
    const currentPage = this.currentPage;
    const pageNumbers = [];

    if (totalPages <= 5) {
      // กรณีที่มีหน้าไม่เกิน 5 หน้า
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // แสดงหน้าแรก
      pageNumbers.push(1);

      // แสดง "..." ถ้าหน้าปัจจุบันห่างจากหน้าแรกมากกว่า 2
      if (currentPage > 2) {
        pageNumbers.push('...');
      }

      // แสดงหน้าก่อนหน้า, หน้าปัจจุบัน, และหน้าถัดไป
      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(currentPage + 1, totalPages - 1);
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      // แสดง "..." ถ้าหน้าปัจจุบันห่างจากหน้าสุดท้ายมากกว่า 2
      if (currentPage < totalPages - 2) {
        pageNumbers.push('...');
      }

      // แสดงหน้าสุดท้าย
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
    },
    pageNumbers() {
    let startPage = 1;
    let endPage = this.totalPages;

    if (this.totalPages > 5) {
      if (this.currentPage <= 3) {
        endPage = 5;
      } else if (this.currentPage + 2 >= this.totalPages) {
        startPage = this.totalPages - 4;
      } else {
        startPage = this.currentPage - 2;
        endPage = this.currentPage + 2;
      }
    }

    let pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  },
  },
  mounted() {
    // this.fetchPrListData();
    // เพิ่มการเชื่อมต่อ WebSocket
    // socket.on('message', (message) => {
    //   if (message.type === 'pr_status_updated' || message.type === 'pr_list_updated') {
    //     console.log('PR status updated:', message.data);
    //     this.fetchPrListData(); // อัปเดตข้อมูลเมื่อมีการเปลี่ยนแปลงสถานะ PR
    //   }
    // });
    this.setActiveSectionFromQuery();
  },
  methods: {
    goToPageExclude(page) {
    this.currentPage = page;
  },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;  // สลับสถานะเปิด/ปิด
    },
    handleOutsideClick(event) {
      // ถ้าคลิกข้างนอก sidebar ให้ปิด sidebar
      if (this.isSidebarOpen && !this.$refs.sidebar.contains(event.target)) {
        this.isSidebarOpen = false;
      }
    },
    statusClass(status) {
      return {
        'bg-yellow-100 text-yellow-800': status === 'รอตรวจสอบ',
        'bg-green-100 text-green-800': status === 'เสร็จสิ้น' || status ==='สำเร็จ' || status === 'รับอะไหล่แล้ว' || status === 'สั่งซื้อครบแล้ว',
        'bg-red-100 text-red-800': status === 'ปิดใบ PR',
        'bg-blue-100 text-blue-800': status === 'กำลังดำเนินการ',
        'bg-gray-100 text-gray-800': status === 'ทั้งหมด',
        'bg-purple-100 text-purple-800': status === 'รอผจก.หน่วยงานตรวจสอบ',
        'bg-indigo-100 text-indigo-800': status === 'รอส่วนกลางตรวจสอบ',
        'bg-teal-100 text-teal-800': status === 'รอเจ้าหน้าที่ธุรการจัดซื้อ',
        'bg-orange-100 text-orange-800': status === 'อยู่ระหว่างการสั่งซื้อ',
        'bg-pink-100 text-pink-800': status === 'ยกเลิกใบ PR',
        'bg-cyan-100 text-cyan-800': status === 'ปิดใบสั่งซื้อ',
        // 'bg-lime-100 text-lime-800': status === 'รับอะไหล่แล้ว',
        'bg-rose-100 text-rose-800': status === 'ไม่อนุมัติ',
        'bg-amber-100 text-amber-800': status === 'รอการอนุมัติ',
        'bg-violet-100 text-violet-800': status === 'รอการสั่งซื้อ',
        'bg-fuchsia-100 text-fuchsia-800': status === 'รอรับอะไหล่',
        
      };
    },
    prevPageExclude() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPageExclude() {
      if (this.currentPage < this.totalPagesExclude) {
        this.currentPage++;
      }
    },
     // Existing methods...
  markAsViewed(itemId) {
    if (!this.viewedItems.includes(itemId)) {
      this.viewedItems.push(itemId);
      localStorage.setItem('viewedItems', JSON.stringify(this.viewedItems));
    }
  },
  loadViewedItems() {
    const viewedItems = localStorage.getItem('viewedItems');
    if (viewedItems) {
      this.viewedItems = JSON.parse(viewedItems);
    }
  },
  async fetchUser() {
    const token = localStorage.getItem('userToken');
    if (!token) {
        this.$router.push({ name: 'Login' });
        return;
    }
    try {
        const response = await axios.get('https://brk-group.org/api/user', {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const userData = response.data;
        this.user = {
            login_id: userData.login_id,
            fullName: `${userData.prefixname}${userData.fname} ${userData.lname}`,
            agency: userData.agency,
            group_id: userData.group_id, // Assuming you have group_id
            position_lo: userData.position_lo
        };
        // console.log('Fetched user agency:', this.user.agency);
        // console.log('Fetched user group_id:', this.user.group_id); // ตรวจสอบค่า group_id ที่ได้มา
    } catch (error) {
        console.error('Error fetching user:', error);
        if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'Login' });
        } else {
            alert('เกิดข้อผิดพลาดในการดึงข้อมูลผู้ใช้');
        }
    }
},
goToPage(page) {
    // ตรวจสอบว่าปุ่มที่กดไม่ใช่ปุ่ม "..." ก่อนจะดำเนินการ
    if (page !== '...') {
      this.currentPage = page;
    }
  },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPOPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPOPage() {
      if (this.currentPage < this.totalPOPages) {
        this.currentPage++;
      }
    },
    formatDate(date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    },
    setActiveSectionFromQuery() {
      const query = this.$route.query;
      if (query.activeSection) {
        this.activeSection = query.activeSection;
      }
      // ทำการเรียกฟังก์ชันที่เกี่ยวข้องกับ activeSection
      this.setActiveSection(this.activeSection);
    },
    setActiveSection(section) {
      this.activeSection = section;
      if (section === 'pr-list') {
        this.fetchPrListDataExclude()
      } else if (section === 'pr-own') {
        this.fetchPrListData();
      } else if (section === 'po-list') {
        this.fetchPoListData();
      }
    },
    setPrType(type) {
      this.prType = type;
      this.currentPage = 1; // Reset page to 1 when type changes
      this.fetchPrListData(); // Fetch data again when type changes
      this.fetchPrListDataExclude();
    },
    async fetchPoListData() {
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.get('https://brk-group.org/api/po_list', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.poListData = response.data.sort((a, b) => b.id - a.id); 
        // this.poListData = response.data;
        // console.log('PO Data fetched:', this.poListData); // ตรวจสอบข้อมูลที่ดึงมา
      } catch (error) {
        console.error('Error fetching PO list data:', error);
      }
    },
    async fetchPrListData() {
  try {
    const token = localStorage.getItem('userToken');
    const response = await axios.get(`https://brk-group.org/api/purchase_requisitions`, {
      headers: { 'Authorization': `Bearer ${token}` },
      params: { 
        type: this.prType,
        group_id: this.user.agency || '' // ส่งค่า group_id ไปยัง API

      }
    });
    this.prListData = response.data.sort((a, b) => b.id - a.id);
    console.log('Data fetched:', this.prListData); // ตรวจสอบข้อมูลที่ดึงมา
  } catch (error) {
    console.error('Error fetching PR list data:', error);
  }
},

async fetchPrListDataExclude() {
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.get(`https://brk-group.org/api/purchase_requisitions_exclude`, {
          headers: { 'Authorization': `Bearer ${token}` },
          params: { 
            type: this.prType,
       group_id: this.user.agency || '' // ส่งค่า group_id ไปยัง API
          }
        });
        this.prListDataExclude = response.data.sort((a, b) => b.id - a.id);
        console.log('Data fetched (Exclude):', this.prListDataExclude); // ตรวจสอบข้อมูลที่ดึงมา
      } catch (error) {
        console.error('Error fetching PR list data exclude:', error);
      }
    },

    // async fetchPoListData() {
    //   try {
    //     const token = localStorage.getItem('userToken');
    //     const response = await axios.get('https://brk-group.org/api/po_list', {
    //       headers: { 'Authorization': `Bearer ${token}` }
    //     });
    //     this.poListData = response.data;
    //   } catch (error) {
    //     console.error('Error fetching PO list data:', error);
    //   }
    // },
    countStatus(status) {
      if (status === 'ทั้งหมด') {
        return this.prListData.length;
      }
      return this.prListData.filter(item => item.status === status).length;
    },
    countAgencyStatus(status) {
      if (status === 'ทั้งหมด') {
        return this.prListDataExclude.length;
      }
      return this.prListDataExclude.filter(item => item.status === status).length;
    },
    // countPoStatus(status) {
    //   if (status === 'ทั้งหมด') {
    //     return this.poListData.length;
    //   }
    //   return this.poListData.filter(po => po.status === status).length;
    // },
    countPoStatus(status) {
    return this.poListData.filter(item => item.status === status).length;
  },
    filterList() {
      this.filteredData = this.testData.filter(item => {
        return this.statusFilter === 'ทั้งหมด' || item.status === this.statusFilter;
      });
    }
  },
  watch: {
    statusFilter() {
      this.filterList();
    },
  },
}
</script>

<style scoped>
.sidebar {
  background-color: #f8f9fa;
  padding: 16px;
  width: 256px;
  min-height: 100vh; /* Ensure the sidebar takes the full height of the screen */
}
.sidebar h2 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.sidebar ul {
  list-style-type: none;
  padding: 0;
}
.sidebar li {
  margin-bottom: 0.5rem;
}
.sidebar a,
.sidebar button {
  display: block;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s;
  width: 100%;
  text-align: left;
}
.sidebar a:hover,
.sidebar button:hover {
  background-color: #e2e6ea;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
