<template>
  <div class="container mx-auto p-4">
    <h1 class="text-2xl font-bold mb-4">รายการสินค้า</h1>
    <div class="flex justify-between items-center mt-8 mb-4">
      <nav aria-label="Page navigation">
    <ul class="pagination">
          <!-- First page button -->
<li :class="{ 'disabled': currentPage === 1 }">
  <a @click.prevent="changePage(1)" href="#" aria-label="First">
    หน้าแรก
  </a>
</li>
      <li :class="{ 'disabled': currentPage === 1 }">
        <a @click.prevent="currentPage > 1 && changePage(currentPage - 1)" href="#" aria-label="Previous">
          &lt; ย้อนกลับ
        </a>
      </li>
      
      <!-- Only show up to 5 pagination buttons -->
      <li v-for="page in paginationRange" :key="page" :class="{ 'active': currentPage === page }">
        <a @click.prevent="changePage(page)" href="#">
          {{ page }}
        </a>
      </li>

      <li :class="{ 'disabled': currentPage === totalPages || totalPages === 0 }">
        <a @click.prevent="currentPage < totalPages && changePage(currentPage + 1)" href="#" aria-label="Next">
          ถัดไป &gt;
        </a>
      </li>

            <!-- Last page button -->
<li :class="{ 'disabled': currentPage === totalPages || totalPages === 0 }">
  <a @click.prevent="changePage(totalPages)" href="#" aria-label="Last">
    หน้าท้าย
  </a>
</li>
    </ul>
    




  </nav>
      <div>
        <span>จำนวนสินค้าทั้งหมด: {{ totalProducts }} รายการ</span>
      </div>
    </div>

    <div class="mb-4 flex flex-wrap items-center justify-between gap-4">
      <input v-model="searchQuery" placeholder="ค้นหาสินค้า" @input="fetchProducts" class="border p-2 rounded w-full md:w-auto flex-grow"/>
  <input v-model="searchCategory" placeholder="ค้นหาตามประเภทสินค้า" @input="fetchProducts" class="border p-2 rounded w-full md:w-auto flex-grow"/>
  <input v-model="searchBrand" placeholder="ค้นหาตามยี่ห้อสินค้า" @input="fetchProducts" class="border p-2 rounded w-full md:w-auto flex-grow"/>
      <button @click="showModal = true; resetForm()" class="ml-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">เพิ่มสินค้า</button>
    </div>

    <div v-if="products.length" class="overflow-x-auto">
      <table class="w-full border-collapse border">
        <thead>
     
  <tr>
    <th class="border p-2">ลำดับ</th>
    <th class="border p-2">รหัสสินค้า</th>
    <th class="border p-2">ชื่อสินค้า</th>
    <th class="border p-2">ประเภทรถ</th>
    <th class="border p-2">ยี่ห้อสินค้า</th>
    <th class="border p-2">ประเภทสินค้า</th>
    <th class="border p-2">ประเภทอะไหล่</th>
    <th class="border p-2">หน่วยเรียก</th>
    <th class="border p-2">รายละเอียด</th>
    <th class="border p-2">รูปภาพ</th>
    <th class="border p-2">จัดการ</th>
  </tr>
</thead>
<tbody>
  <tr v-for="(product, index) in products" :key="product.product_id">
    <td>{{ index + 1 }}</td>
    <td>{{ product.product_code }}</td>
    <td>{{ product.product_name }}</td>
    <td>{{ product.vehicle_type }}</td>
    <td>{{ product.brand_name }}</td>
    <td>{{ product.product_type }}</td>
    <td>{{ product.part_type }}</td>
    <td>{{ product.unit_name }}</td>
    <td>{{ product.description }}</td>
    <td>
      <img :src="product.image_url" :alt="product.product_name" class="w-20 h-20 object-cover mx-auto cursor-pointer" @click="openImageModal(product.image_url)" />
    </td>
    <td>
              <button @click="editProduct(product)" class="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-1 px-2 rounded">แก้ไข</button>
              <button @click="deleteProduct(product.product_id)" class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded ml-2">ลบ</button>

            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p v-else class="text-gray-500 mt-4">ไม่พบสินค้าที่คุณค้นหา ลองค้นหาด้วยคำอื่นหรือ<a href="/products" class="text-blue-500 hover:text-blue-700">ดูสินค้าทั้งหมด</a>.</p>

    <product-form-modal
  :showModal="showModal"
  :form="form"
  :productTypes="productTypes"
  :vehicleTypes="vehicleTypes"
  :brands="brands"
  :partTypes="partTypes"
  :isEdit="isEdit"
  @product-uploaded="fetchProducts"
  @close="toggleModal"
></product-form-modal>


  </div>
  <div v-if="showImageModal" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50" @click="closeImageModal">
    <div class="bg-white p-4 max-w-2xl max-h-full overflow-auto">
      <img :src="selectedImage" class="w-full h-auto" style="transform: scale(var(--zoom)); cursor: zoom-in;" @wheel="zoomImage" />
    </div>
  </div>
  <div v-if="loading" class="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
      <div class="spinner"></div>
    </div>
</template>

<script>
import axios from 'axios';
import ProductFormModal from './ProductFormModal.vue';
import Swal from 'sweetalert2';
export default {
  components: {
    ProductFormModal
  },
  data() {
    return {
      searchQuery: '',
      searchCategory: '',
    searchBrand: '',
      products: [],
      productTypes: [],
      vehicleTypes: [],
      brands: [],
      partTypes: [],
      form: {
        product_id: null,
        product_name: '',
        product_code: '',
        product_type_id: null,
        unit_name: '',
        description: '',
        image_id: ''
      },
      showModal: false,
      isEdit: false,
      currentPage: 1,
      totalPages: 0,
      pageSize: 10, // จำนวนรายการต่อหน้า
      totalProducts: 0,
      selectedProductTypeId: '',
      selectedImageUrl: null,
      showImageModal: false,
      loading: false, // สถานะ loading เริ่มต้นเป็น false
    };
  },
  mounted() {
    this.fetchAllData();
    this.localForm = this.form ? { ...this.form } : this.defaultForm();
  },
  computed: {
    paginationRange() {
      const sideSize = 2; // pages on each side of current
      const range = [];
      for (let i = Math.max(1, this.currentPage - sideSize); i <= Math.min(this.totalPages, this.currentPage + sideSize); i++) {
        range.push(i);
      }
      return range;
    },
  },
  methods: {
    async fetchAllData() {
      // เรียกข้อมูลสำหรับแต่ละประเภท
      await this.fetchVehicleTypes();
      await this.fetchBrands();
      await this.fetchProductTypes();
      await this.fetchPartTypes();
      this.fetchProducts();
    },
    async fetchVehicleTypes() {
  try {
    const response = await axios.get('https://brk-group.org/api/vehicle-types');
    this.vehicleTypes = response.data;
    console.log('Vehicle Types:', this.vehicleTypes);
  } catch (error) {
    console.error('Error fetching vehicle types:', error);
  }
},
  async fetchBrands() {
    try {
      const response = await axios.get(`https://brk-group.org/api/brands?search=${this.searchQuery}`)
      this.brands = response.data;
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  },
  async fetchProductTypes() {
    try {
      const response = await axios.get('https://brk-group.org/api/product-types');
      this.productTypes = response.data;
    } catch (error) {
      console.error('Error fetching product types:', error);
    }
  },
  async fetchPartTypes() {
    try {
      const response = await axios.get('https://brk-group.org/api/part-types');
      this.partTypes = response.data;
    } catch (error) {
      console.error('Error fetching part types:', error);
    }
  },
  findTypeName(id, list) {
  if (!list || !Array.isArray(list)) {
    console.error('List is not an array:', list);
    return 'ไม่พบข้อมูล';
  }
  let item;
  if (list[0] && list[0].vehicle_type_id !== undefined) {
    item = list.find(item => item.vehicle_type_id === id);
  } else if (list[0] && list[0].brand_id !== undefined) {
    item = list.find(item => item.brand_id === id);
  } else if (list[0] && list[0].product_type_id !== undefined) {
    item = list.find(item => item.product_type_id === id);
  } else if (list[0] && list[0].part_type_id !== undefined) {
    item = list.find(item => item.part_type_id === id);
  } else {
    item = list.find(item => item.id === id);
  }
  return item ? item.name : 'ไม่พบข้อมูล';
},


fetchProducts() {
    const url = `https://brk-group.org/api/products?searchQuery=${encodeURIComponent(this.searchQuery)}&typeId=${this.selectedProductTypeId}&searchCategory=${encodeURIComponent(this.searchCategory)}&searchBrand=${encodeURIComponent(this.searchBrand)}&page=${this.currentPage}&limit=${this.pageSize}`;
    axios.get(url)
      .then(response => {
        if (response.data && response.data.products) {
          this.products = response.data.products.map(product => {
            product.image_url = product.image_url ? this.transformImageUrl(product.image_url) : null;
            return product;
          });
          this.totalPages = Math.ceil(response.data.total / this.pageSize);
          this.totalProducts = response.data.total;
        } else {
          this.products = [];
          this.totalProducts = 0;
        }
      })
      .catch(error => {
        console.error('Error fetching products:', error);
        this.products = [];
        this.totalProducts = 0;
      });
  },
  transformImageUrl(imagePath) {
    // ตรวจสอบว่า `imagePath` ไม่เป็น null หรือ undefined
    if (!imagePath) {
      return null; // หรือคืนค่า default image ถ้าต้องการ
    }
    // เตรียม Base URL สำหรับ images
    const baseUrl = 'https://brkmeeting-room.online/';
    return baseUrl + imagePath.split('/').pop(); // ใช้ .pop() เพื่อเอาชื่อไฟล์ตอนท้าย
  },
  // ฟังก์ชันเปิด modal
  openImageModal(imageUrl) {
      this.selectedImage = imageUrl;
      this.showImageModal = true;
      document.documentElement.style.setProperty('--zoom', '1');
    },

    // ฟังก์ชันปิด modal
    closeImageModal() {
      this.showImageModal = false;
      this.selectedImage = '';
    },

    // ฟังก์ชันซูมรูปภาพ
    zoomImage(event) {
      event.preventDefault();
      const scale = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--zoom'));
      let newScale = scale + event.deltaY * -0.01;
      
      // จำกัดขนาดการซูมระหว่าง 1 ถึง 3
      newScale = Math.min(Math.max(newScale, 1), 3);
      document.documentElement.style.setProperty('--zoom', newScale.toString());
    },
   
    changePage(page) {
      if (page < 1 || page > this.totalPages) return;
      this.currentPage = page;
      this.fetchProducts(); // โหลดข้อมูลสินค้าใหม่ตามหน้าที่เลือก
    },
    editProduct(product) {
  const vehicleTypeId = this.vehicleTypes.find(v => v.type_name === product.vehicle_type)?.vehicle_type_id;
  const brandId = this.brands.find(b => b.brand_name === product.brand_name)?.brand_id;
  const productTypeId = this.productTypes.find(pt => pt.description === product.product_type)?.product_type_id;
  const partTypeId = this.partTypes.find(part => part.type_name === product.part_type)?.part_type_id;

  this.form = {
    ...product,
    vehicle_type_id: vehicleTypeId,
    brand_id: brandId,
    product_type_id: productTypeId,
    part_type_id: partTypeId
  };
  this.isEdit = true;
  this.showModal = true;
},

    async deleteProduct(productId) {
      const result = await Swal.fire({
        title: 'คุณแน่ใจหรือไม่?',
        text: "คุณไม่สามารถย้อนกลับการกระทำนี้ได้!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ใช่, ยืนยัน!',
        cancelButtonText: 'ยกเลิก'
      });
      
      if (result.isConfirmed) {
        axios.delete(`https://brk-group.org/products/${productId}`)
          .then(() => {
            Swal.fire(
              'Deleted!',
              'สินค้าของคุณถูกลบแล้ว.',
              'success'
            );
            this.fetchProducts(); // รีเฟรชรายการสินค้า
          })
          .catch(error => {
            console.error('Error deleting product:', error);
            Swal.fire('ผิดพลาด', 'เกิดข้อผิดพลาดในการลบสินค้า', 'error');
          });
      }
    },
    updateForm(updatedForm) {
      this.form = updatedForm;
      this.submitForm();
    },
    async submitForm() {
    this.loading = true; // เริ่มสถานะ loading
    const url = this.isEdit ? `https://brk-group.org/api/products/${this.form.product_id}` : 'https://brk-group.org/api/products';
    const method = this.isEdit ? 'put' : 'post';
    
    try {
      await axios[method](url, this.form);
      this.toggleModal();
      this.resetForm();
      this.fetchProducts();
      const message = this.isEdit ? 'แก้ไขสินค้าสำเร็จ' : 'เพิ่มสินค้าสำเร็จ';
      Swal.fire('สำเร็จ!', message, 'success');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        Swal.fire('ผิดพลาด', error.response.data, 'error');
      } else {
        console.error('Error submitting product:', error);
        Swal.fire('ผิดพลาด', 'เกิดข้อผิดพลาดในการส่งข้อมูลสินค้า', 'error');
      }
    } finally {
      this.loading = false; // จบสถานะ loading
    }
  },
    resetForm() {
      this.form = {
        product_id: null,
        product_name: '',
        product_type_id: null,
        unit_name: '',
        description: '',
        image_url: ''
      };
      this.isEdit = false;
    },
    toggleModal() {
      this.showModal = !this.showModal;
      if (!this.showModal) {
        this.resetForm();
        
      }
    }
  }
};
</script>
<style scoped>

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
}
/* Spinner Animation */
@keyframes spin {
  to { transform: rotate(360deg); }
}

@media (max-width: 640px) {
  .modal-container {
    max-height: 90vh;
    height: 80%;
    overflow-y: auto;
  }
}
/* กำหนดขนาดและลักษณะของรูปภาพ */
.product-image {
  width: 150px; /* หรือขนาดที่คุณต้องการ */
  height: auto; /* ส่วนสูงจะปรับตามขนาดกว้างโดยอัตโนมัติ */
  object-fit: cover; /* รูปภาพจะครอบคลุมพื้นที่โดยไม่เสียสัดส่วน */
  border-radius: 15px; /* ใส่เป็นได้ถ้าคุณต้องการให้มุมรูปภาพโค้งมน */
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination li {
  list-style-type: none;
  margin: 0 5px;
}

.pagination li a {
  text-decoration: none;
  color: #333;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.pagination li a:hover {
  background-color: #f0f0f0;
}

.pagination li.active a {
  background-color: #007bff;
  color: white;
}

</style>